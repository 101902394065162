/* eslint-disable react/no-unescaped-entities */
/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import LabButton from "../../../all-components/LabButton";

class CatClickFirstNavigate extends Component {
  constructor(props) {
    super(props);
    const { path } = this.props;
    CatClickFirstNavigate.handleOnclick =
      CatClickFirstNavigate.handleOnclick.bind(this, path);
  }

  static handleOnclick(path) {
    console.log(path);
    navigate(path);
  }

  render() {
    return (
      <div id={"catClickMessage"}>
        <p
          aria-label={
            "Cat clicked! Please click the 'next' button to continue."
          }
        >
          Cat clicked! Please click the 'next' button to continue.
        </p>
        <br />
        <LabButton onClick={CatClickFirstNavigate.handleOnclick} label={"Next"}>
          Next
        </LabButton>
      </div>
    );
  }
}

CatClickFirstNavigate.propTypes = {
  path: PropTypes.string,
};

export default CatClickFirstNavigate;
