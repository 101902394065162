import React, { useState } from "react";
import BrandedALLModal from "./BrandedALLModal";
import PropTypes from "prop-types";
import LabRow from "../body/profilepage/components/LabRow";
import ALLButton from "./ALLButton";
import { SUCCESS } from "../../constants/notifications";
import useMainStateContext from "../../reducers/MainContext";

const NewStudentProgress = (props) => {
  const { group, enrolledStudents, assignedLabs } = props;
  const { actions } = useMainStateContext();

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      document.getElementById("groupCode").innerHTML.trim(),
    );
    actions.showSnackbar("Successfully copied code!", SUCCESS);
  };

  return (
    <>
      <a
        onClick={toggleModal}
        className="tw-absolute tw-right-0 tw-top-[45%] tw-cursor-pointer
                tw-font-poppins tw-bg-darkGray tw-text-white tw-p-2 tw-font-medium"
      >
        Student Progress
      </a>
      <BrandedALLModal
        direction={"column"}
        isOpen={modalOpen}
        toggle={toggleModal}
      >
        <div className={"tw-px-[2rem] tw-py-[1rem]"}>
          <div className={"tw-font-poppins"}>
            <p className={"tw-title tw-mb-3"}>View Group as the Instructor</p>
            <p className={"tw-title tw-text-2xl"}>{group.groupName}</p>
            <div className={"tw-flex tw-flex-row tw-items-center tw-my-3"}>
              <p className={"tw-text-sm"}> Group Code: </p>
              <p id={"groupCode"} className={"tw-mx-3 tw-font-bold tw-text-md"}>
                {" "}
                {group.code}
              </p>
              <ALLButton
                className={"tw-h-[3rem] tw-mb-0 tw-ml-5"}
                label={"Copy Code"}
                onClick={copyToClipboard}
              />
            </div>
            <p className={"tw-w-3/4 tw-text-sm"}>
              Share this group code with your students to get them enrolled into
              the class. All they have to do to get started is enter the code!
            </p>
          </div>
          {assignedLabs.map((lab, key) => {
            return (
              <LabRow
                key={key}
                lab={lab}
                studentProgress={true}
                group={group}
                enrolledStudents={enrolledStudents}
              />
            );
          })}
        </div>
      </BrandedALLModal>
    </>
  );
};

NewStudentProgress.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number,
    groupName: PropTypes.string,
    code: PropTypes.string,
  }),
  enrolledStudents: PropTypes.array,
  assignedLabs: PropTypes.array,
};

export default NewStudentProgress;
