/* eslint-disable require-jsdoc */
import React, { Component } from "react";

class AppInstructions extends Component {
  render() {
    return (
      <div className="tw-p-[3rem]">
        <p className={"tw-body-text tw-font-medium tw-text-[1.5rem]"}>
          Choose appropriate difficulty to begin exercise! Note: This activity
          requires headphones or speakers.
        </p>
      </div>
    );
  }
}

export default AppInstructions;
