const ENROLL_SUCCESS = "You have successfully enrolled in this group!";
const ENROLL_ERROR =
  "An error has occurred and you could not be enrolled in this group.";
const UNENROLL_SUCCESS = "You have successfully un-enrolled from this group.";
const UNENROLL_ERROR =
  "An error has occurred and you could not be un-enrolled from this group.";
const LOGOUT_SUCCESS = "Logout successful!";
const LOGOUT_ERROR = "User could not be logged out.";
const EXERCISE_IN_PROGRESS =
  "The exercise is still in progress! Please complete the exercise";
const DELETE_GROUP_SUCCESS = "Group successfully deleted!";
const UPDATE_GROUP_SUCCESS = "Group successfully updated!";
const CREATE_GROUP_SUCCESS = "Group successfully created!";
const GROUP_ERROR =
  "An unexpected error occurred when creating/updating this group.";
const REPAIR_ERROR = "Errors in Repair. Please fix.";
const REPAIR_SUCCESS = "Repair complete!";
const SUCCESS = "success";
const ERROR = "error";

module.exports = {
  ENROLL_SUCCESS,
  ENROLL_ERROR,
  UNENROLL_SUCCESS,
  UNENROLL_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  EXERCISE_IN_PROGRESS,
  CREATE_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  GROUP_ERROR,
  SUCCESS,
  ERROR,
  REPAIR_SUCCESS,
  REPAIR_ERROR,
};
