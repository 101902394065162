export const EXERCISE_PLAYING = "EXERCISE_PLAYING";
export const EXERCISE_IDLE = "EXERCISE_IDLE";
export const LAB_ID = 2;

// Used to select the exercise option
export const SELECT_OPTION = "SELECT_OPTION";
export const RESET_OPTION = "RESET_OPTION";
export const RESET = "RESET";

// Used to control the color change popup
export const UPDATE_DEFAULT_COLORS = "UPDATE_DEFAULT_COLORS";
export const UPDATE_EXERCISE_COLORS = "UPDATE_EXERCISE_COLORS";
export const ACTIVATE_POPUP = "ACTIVATE_POPUP";
export const RESET_COLORS = "RESET_COLORS";
export const CHANGED_RESET = "CHANGED_RESET";
export const BACKGROUND_TO_WHITE = "BACKGROUND_TO_WHITE";
export const BACKGROUND_TO_GREY = "BACKGROUND_TO_GREY";
export const RESET_BACKGROUND = "RESET_BACKGROUND";

// Used to control the different state options
// (about page, stats page, exercise page)
export const START_EXERCISE = "START_EXERCISE";
export const END_EXERCISE = "END_EXERCISE";
export const FIRST_EXERCISE = "FIRST_EXERCISE";
export const ABOUT_STATE = "ABOUT_STATE";
export const END_ABOUT_STATE = "END_ABOUT_STATE";
export const STAT_STATE = "STAT_STATE";
export const END_STAT_STATE = "END_STAT_STATE";
export const INFO_STATE = "INFO_STATE";
export const END_INFO_STATE = "END_INFO_STATE";
export const INFO_STATE_TWO = "INFO_STATE_TWO";
export const END_INFO_STATE_TWO = "END_INFO_STATE_TWO";
export const INFO_STATE_THREE = "INFO_STATE_THREE";
export const END_INFO_STATE_THREE = "END_INFO_STATE_THREE";
export const END_SYSTEM = "END_SYSTEM";
export const COLOR_CHANGE = "COLOR_CHANGE";
export const END_COLOR_CHANGE = "END_COLOR_CHANGE";
export const BACK_EXERCISE = "BACK_EXERCISE";

// Used to login users
export const LOGIN = "LOGIN";
export const CLOSE_INFO_POPUP = "CLOSE_INFO_POPUP";
